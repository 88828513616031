<template>
  <div class="flex flex-row mt-4 xl:px-32">
    <div class="w-screen">
      <!-- 头像基本信息 -->
      <div class="bg-white px-4 sm:px-6 lg:px-16">
        <div class="pt-24">
          <div class="space-y-12  lg:space-y-0">
            <div class="md:col-span-2">
              <ul role="list"
                class="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                <li class="sm:py-8">
                  <div class="space-y-5 lg:grid sm:grid-cols-4 sm:items-start sm:gap-6 sm:space-y-0">
                    <div class="">
                      <img class="object-cover shadow-lg rounded-2xl h-64 w-44 mx-auto md:mx-0 md:justify-start " src="../assets/黄平.jpg">
                    </div>
                    <div class="sm:col-span-3">
                      <div class="space-y-4 text-left">
                        <div class="text-lg leading-6 font-extrabold space-y-1 mb-4 mt-8">
                          <h3>黄平 / 研究员</h3>
                          <p>中国科学院 大气物理研究所 季风系统研究中心</p>
                        </div>
                        <div class="text-md pt-2">
                          <p>地址：北京市朝阳区华严里40号大气物理研究所</p>
                          <p>邮编：100029</p>
                          <p class="font-bold">E-mail：huangping@mail.iap.ac.cn</p>
                          <p class="font-bold">Web：
                            <a href="https://www.pinghuang-climate.cn"  class="font-bold text-indigo-600">https://www.pinghuang-climate.cn
                            </a>
                          </p>
                          <p class="font-bold">ResearchGate：
                            <a href="https://www.researchgate.net/profile/Ping-Huang-43"  class="font-bold text-indigo-600">https://www.researchgate.net/profile/Ping-Huang-43
                            </a>
                          </p>
                          <p class="font-bold">Google Scholar：
                            <a href="https://scholar.google.com/citations?user=axbf5o4AAAAJ"  class="font-bold text-indigo-600">https://scholar.google.com/citations?user=axbf5o4AAAAJ
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 个人简介 -->
      <div id="top01" class="bg-white px-4 sm:px-6 lg:px-16 mt-8">
        <div class="relative mx-auto divide-y-2 divide-gray-200">
          <div>
            <h2 class="text-left text-2xl tracking-normal font-extrabold text-gray-900 sm:text-2xl">个人简介</h2>
          </div>
          <div class="mt-4 pt-6 px-0">
            <p class="justify-between text-justify text-lg leading-8 break-all">
              长期以来我的研究主要围绕“热带区域气候对全球变暖的响应机制”展开，研究包括：全球变暖下热带及东亚地区降水和海温的季节和空间分布变化、ENSO对热带及东亚气候影响在全球变暖下的变化、提出校订多模式集合平均预估区域气候变化的新方法、热带太平洋增温分布型差异归因、全球变暖下台风强度变化。发表多篇第一/通讯作者Nature子刊论文，Journal
              of Climate, Climate Dynamics等一区SCI论文。为IPCC AR5和《第三次国家气候变化评估报告》的贡献作者，多项成果写入IPCC AR5的《技术摘要》。长期担任Nature, Nature
              Geoscience, Nature Climate Change, Nature Communications, BAMS, Journal of Climate, Climate Dynamics,
              Scientific
              Reports等期刊审稿人。获国家自然科学基金优秀青年基金(2017)、入选中国科学院创新促进会会员（2016）、优秀会员（2021）、谢义炳青年气象科技奖（2016）等奖励。已指导（或联合指导）硕士、博士研究生多名，所指导学生曾获中国科学院优秀博士学位论文（2017）等奖励。
            </p>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->
      <div id="top01" class="bg-white px-4 sm:px-6 lg:px-16 mt-16">
        <div class="relative mx-auto divide-y-2 divide-gray-200">
          <h2 class="text-left text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl mb-0">教育经历</h2>
          <div class="mt-4"></div>
        </div>
        <div class="mt-4 px-0 text-left text-lg ">
          <p>2004.9-2009.4，地球流体力学，博士，中国科学院大气物理研究所（IAP），
            导师：黄荣辉院士</p>
        </div>
        <div class="mt-4 px-0 text-left text-lg ">
          <p>2004.9-2009.4，大气科学，学士，中国科学技术大学地球与空间科学学院</p>
        </div>
        <!-- <div class="mt-4 px-2 text-left text-xl">
          <p>地球流体力学，博士</p>
          <p>中国科学院大气物理研究所</p>
          <span
            class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-base font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">2004.9-2009.4</span>
        </div>
        <div class="mt-4 px-2 text-left text-xl">
          <p>大气科学，学士</p>
          <p>中国科学技术大学地球与空间科学学院</p>
          <span
            class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-base font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">2000.9-2004.7</span>
        </div> -->
      </div>
      <!-- 主持项目 -->
      <div id="top01" class="bg-white px-4 sm:px-4 lg:px-16 mt-16">
        <div class="relative mx-auto divide-y-2 divide-gray-200">
          <h2 class="text-left text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl mb-0">主持项目</h2>
          <div class="mt-4"></div>
        </div>
        <ol class="text-justify list-disc px-2">
          <li
            class="relative bg-white mt-4 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2020-2023，全球变暖下亚印太交汇区海温与东亚夏季风耦合变化研究，国家自然科学基金面上项目，主持</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2018-2020，热带区域气候对全球变暖的响应机制，国家自然科学基金优秀青年基金项目，主持</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2019-2023，三大洋相互作用机制及其对气候变化的影响，国家重点研发计划，课题负责人</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2020-2022，气候变暖背景下印太交汇区海洋-东亚夏季风-青藏高原气候长期变化之间耦合过程研究，中国科学院海洋大科学研究中心重点部署项目，课题负责人</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2016-2019，中国科学院青年创新促进会人才项目，主持</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2021-2023，中国科学院青年创新促进会优秀会员项目，主持</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2015-2017，全球变暖背景下西北太平洋台风活动的变化特征和趋势及其机理研究，国家自然科学基金重点项目，课题负责人</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2012-2014，对流耦合赤道波动的季节和年际变化特征及其机理，国家自然科学基金青年基金项目，主持</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2014-2018，全球变化背景下南海及周边地区春夏气候变异特征和机理及其对全球气候的反馈作用，国家重点研发计划，骨干</p>
            </div>
          </li>
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="px-0 text-left text-lg ">
              <p>2013-2017，热带西太平洋海洋系统物质能量交换及其影响，中国科学院先导专项，专题负责人</p>
            </div>
          </li>
        </ol>
      </div>
      <!-- 最新研究亮点 -->
      <div id="top03" class="bg-white px-4 sm:px-6 lg:px-16 mt-20">
        <div class="relative  mx-auto divide-y-2 divide-gray-200">
          <h2 class="text-left text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl mb-4">最新研究亮点</h2>
          <div></div>
        </div>
      </div>
      <div class="bg-white px-4 sm:px-6 lg:px-16 mt-4">
        <ol reversed class="text-justify list-disc text-lg  px-2">
          <li
            class="relative bg-white mt-2 py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify  text-gray-600">Zhang, Jiayu, and <b>P. Huang</b>*, 2024: Integrated changes in tropical precipitation and circulation
                 under global warming: Moist static energy budget and the role of sea surface warming pattern. Climate Dynamics, doi: 10.1007/s00382-023-06877-6.
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Wang, Tingyu, and <b>P. Huang</b>*, 2024: Superiority of convolutional neural network model over
                 the dynamical models in predicting the central Pacific ENSO. Adv. Atmos. Sci., doi: 10.1007/s00376-023-3001-1.
              </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Chen, Yue and <b>P. Huang</b>*, 2023: ENSO-induced decadal variability in the tropical Pacific subsurface in CMIP6 models.
                 International Journal of Climatology, 43, 4033-4046, doi: 10.1002/joc.8071.</p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Zhang, Jiayu, and <b>P. Huang</b>*, 2023: Different uncertainty in tropical oceanic and land precipitation 
                sensitivities under global warming. Atmospheric Research, 292, 106850, doi: 10.1016/j.atmosres.2023.106850.</p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Yang, Xianke, <b>P. Huang</b>*, P. Hu, and Z. Wang, 2023: Distinct impacts of two types of summer ENSO with
                 different temporal evolutions on the Asian summer monsoon: Role of the tropical Indo-western Pacific. Journal of Climate, 36, 3917–3936, doi: 10.1175/JCLI-D-22-0532.1
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600"><b>Huang, P.*</b>, X.-T. Zheng, X. Li, K. Hu, and Z.-Q. Zhou, 2023: More complex interactions: Continuing progress in understanding
                 the dynamics of regional climate change under a warming climate. The Innovation, 4, 100398, doi:10.1016/j.xinn.2023.100398.
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Yang, Xianke, and <b>P. Huang</b>*, 2023: Improvements in the relationship between tropical precipitation and 
                sea surface temperature from CMIP5 to CMIP6. Climate Dynamics, 60, 3319–3337, doi:10.1007/s00382-022-06519-3.
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Li, Shufan, and <b>P. Huang</b>*, 2022: An exponential-interval sampling method for evaluating equilibrium 
                climate sensitivity via reducing internal variability noise. Geoscience Letters, 9, 34, doi:10.1186/s40562-022-00244-9. 
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Yang, Xianke, <b>P. Huang</b>*, Yong Liu and Dong Chen, 2022: An Interdecadal Enhancement of the Impact of 
                ENSO on the Summer Northeast Asian Circulation around 1999/2000 through the Silk Road Pattern. Journal of Climate, 35, 3881–3897, doi:10.1175/JCLI-D-22-0195.1. 
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Zhou, Shijie, <b>P. Huang</b>*, Shang-Ping Xie, Gang Huang and Lin Wang, 2022: Varying contributions of fast and slow responses cause 
                asymmetric tropical rainfall change between CO2 ramp-up and ramp-down. Science Bulletin, 67, 1702–1711, doi:10.1016/j.scib.2022.07.010. 
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Xie, Xinru, <b>P. Huang</b>*, S. Zhou, and Jiayu Zhang, 2022: Changes in ENSO-driven Hadley circulation variability under global warming.
                 Atmospheric Research, 274, 106220, doi:10.1016/j.atmosres.2022.106220. 
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Wang, Yanfeng, and <b>P. Huang</b>*, 2022: Potential fire risks in South America under anthropogenic forcing hidden by the Atlantic Multidecadal Oscillation.
                 Nature Communications, 13, 2437, doi:10.1038/s41467-022-30104-1. 
                </p>
            </div>
          </li>
          <li
            class="relative bg-white py-1 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <div class="mt-1">
              <p class=" text-justify text-gray-600">Yang, Xianke, and <b>P. Huang</b>*, 2022: The diversity of ENSO evolution during the typical decaying period determined by an ENSO
                 developing mode. Journal of Climate, 35, 3877–3889, doi:10.1175/JCLI-D-21-0892.1.   
                </p>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- 侧边栏 -->
    <!-- <div class="hidden lg:block lg:fixed lg:w-2/12" style="top:80px;right:0">
      <ul role="list">
        <li @click="top01()"
          class="cursor-pointer relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <div class="mt-1">
            <p class=" text-sm text-gray-600">个人简介</p>
          </div>
        </li>
        <li @click="top02()"
          class="cursor-pointer relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <div class="mt-1">
            <p class=" text-sm text-gray-600">主持项目</p>
          </div>
        </li>
        <li @click="top03()"
          class="cursor-pointer relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <div class="mt-1">
            <p class=" text-sm text-gray-600">最新研究亮点</p>
          </div>
        </li>
      </ul>
    </div> -->
  </div>
</template>


<script>
//import { WOW } from 'wowjs'
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Import Swiper styles
export default {
  data() {
    return {
      menuopen: false,
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      //console.log(swiper);
    };
    const onSlideChange = () => {
      //console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods: {
    openmenu() {
      this.menuopen = !this.menuopen;
    },
    top01() {
      var el = document.getElementById("top01");
      window.scroll({
        top: el.offsetTop,
        behavior: "smooth",
      });
    },
    top02() {
      var el = document.getElementById("top02");
      window.scroll({
        top: el.offsetTop,
        behavior: "smooth",
      });
    },
    top03() {
      var el = document.getElementById("top03");
      window.scroll({
        top: el.offsetTop,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    //    var wow = new WOW({
    //      boxClass: 'wow', // 需要执行动画的元素的 class
    //      animateClass: 'animated', //animation.css 动画的 class
    //      offset: 300, // 距离可视区域多少开始执行动画
    //      mobile: true, // 是否在移动设备上执行动画
    //      live: true, // 异步加载的内容是否有效
    //    })
    //    wow.init()
  }
};
</script>