import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'; 
import 'hover.css'
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'

createApp(App).use(store).use(router).use(animated).mount('#app')
